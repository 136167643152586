import Vue from "vue";
// eslint-disable-next-line
import Vuetify from "vuetify/lib";
import de from "../locale/de";
import en from "../locale/en";
import it from "../locale/it";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { de, en, it },
    current: "de"
  }
});
