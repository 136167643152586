import { en } from "vuetify/src/locale";

export default {
  ...en,
  user: "User",
  password: "Password",
  language: "Language",
  submit: "Login",
  theme: "Theme",
  sessionExits:
    "There is already a session with that username. Would you like to sign up anyway and destroying the previous session?",
  sessionKill: "Delete session",
  wrongAuth: "Wrong user or password",
  userDateExpired: "User license is expired",
  dateExpired: "License has expired",
  userNoRole: "User doesn't have a role",
  userNoAccess: "User has no authorization for designer",
  sessionExpired: "Session expired",
  release: "Release",
  autologin: "Automatic login",
  blue: "Blue",
  dark: "Darkmode",
  material: "Material",
  quartz: "Quartz",
  client: "Client"
};
