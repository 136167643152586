






















































































































































import queryString from "query-string";
import Vue from "vue";
import Logo from "./Logo.vue";
import ErrorMessages from "./ErrorMessages.vue";
import axios from "axios";
import VueCookies from "vue-cookies";

Vue.use(VueCookies);

export default Vue.extend({
  name: "LoginForm",

  components: {
    Logo,
    ErrorMessages
  },

  data: () => ({
    themes: window.config.themes,
    clients: [],
    languages: [
      {
        text: "Deutsch",
        value: "de",
        flag: "de"
      },
      {
        text: "English",
        value: "en",
        flag: "gb"
      },
      {
        text: "Italiano",
        value: "it",
        flag: "it"
      }
    ],
    releases: ["head"],
    application: "",
    releaseEnabled: true,
    themeEnabled: true,
    language: "de",
    theme: "",
    clientEnabled: false,
    isDisabled: true
  }),
  methods: {
    changeTheme: function(theme: string) {
      const isDark = theme == "dark";
      this.$vuetify.theme.dark = isDark;
    },
    changeLocale(lang: string) {
      this.$vuetify.lang.current = lang;
    },
    submitForm: function() {
      eval('document.getElementById("loginForm").submit();');
    },
    getBrowserLang: function() {
      return window.navigator.language;
    },
    defaultLanguage: function(lang: string) {
      const defaultLanguage = "en";
      const query = queryString.parse(window.location.search);
      const cookievalue = Vue.$cookies.get("CS_LANGUAGE");
      let language = lang != null && lang != "" ? lang : cookievalue ? cookievalue : this.getBrowserLang();
      language = language.substr(0, 2).toLowerCase();
      language = query.countries ? query.countries : query.lang ? query.lang : language;

      switch (language) {
        case "en":
        case "de":
        case "it":
          //case "ru":
          //case "fr":
          //case "es":
          break;
        default:
          language = defaultLanguage;
      }
      return language;
    },
    checkUserMandant: function() {
     
      if (this.$data.clientEnabled) {
        if (this.query.username.trim() !== "") {
          const url =
            "../REST/adminmandator/list/user/" +
            encodeURIComponent(this.query.username) +
            "?time=" +
            Date.now();
          axios
            .get(url)
            .then(response => {
              this.clients = response.data;
              this.isDisabled = false;
            })
            .catch(error => {
              if (error.response && error.response.status === 404) {
                this.clients = [];
                this.isDisabled = false;
              } else {
                this.isDisabled = true;
                console.error(error);
              }
            });
        } else {
          this.isDisabled = true;
        }
      } else {
        this.clients = [];
        this.isDisabled = false;
      }
    }
  },
  mounted: function() {
    const application = document.getElementById("application")?.getAttribute("value");
    const isIE = window.navigator ? /trident/.test(window.navigator.userAgent.toLowerCase()) : false;

    let theme = Vue.$cookies.get("CS_THEME");
    if (isIE) {
      theme = "";
      this.$data.themeEnabled = false;
    }

    if (theme) {
      this.$data.theme = theme;
      this.changeTheme(theme);
    }

    this.$data.language = this.defaultLanguage("");

    if (this.query.username && this.query.password) {
      this.$nextTick(this.submitForm);
    } else {
      document.getElementById("username")?.focus();
    }

   

    this.$data.clientEnabled = false;
    axios
      .get("../REST/adminmandator/enabled?time=" + Date.now())
      .then(() => {
        this.$data.clientEnabled = true;
      })
      .catch(reason => {
        if (reason.response && reason.response.status === 403) {
          console.info("Client(Mandant) is disabled.");
        } else {
          console.error(reason);
        }
      });

    if (application === "workspace") {
      this.$data.releaseEnabled = false;
      return;
    }
    axios
      .get("../REST/release/list")
      .then(response => {
        this.releases = response.data;
      })
      .catch(reason => console.error(reason));
  },
  computed: {
    query: function() {
      return queryString.parse(window.location.search);
    }
  }
});
