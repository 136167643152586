










































import queryString from "query-string";
import Vue from "vue";

export default Vue.extend({
  name: "ErrorMessages",
  computed: {
    query: function() {
      return queryString.parse(window.location.search);
    }
  }
});
