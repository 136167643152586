import { it } from "vuetify/src/locale";

export default {
  ...it,
  user: "Utente",
  password: "Password",
  language: "Lingua",
  submit: "Accesso",
  theme: "Tema",
  sessionExits:
    "Esiste già una sessione con quel nome utente. Vuoi iscriverti comunque e distruggere la sessione precedente?",
  sessionKill: "Eliminare sessione",
  wrongAuth: "Utente o password errata",
  userDateExpired: "Licenza d'uso è scaduta",
  dateExpired: "Licenza è scaduta",
  userNoRole: "User doesn't have a role",
  userNoAccess: "User has no authorization for designer",
  sessionExpired: "Session expired",
  release: "Rilascio",
  autologin: "Automatic login",
  blue: "Blu",
  dark: "Darkmode",
  material: "Material",
  quartz: "Quartz"
};
