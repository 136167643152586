import { de } from "vuetify/src/locale";

export default {
  ...de,
  user: "Benutzer",
  password: "Passwort",
  language: "Sprache",
  submit: "Anmelden",
  theme: "Thema",
  sessionExits:
    "Es existiert bereits eine Sitzung mit diesem Benutzernamen. Möchten Sie sich trotzdem anmelden und somit die vorherige Sitzung löschen?",
  sessionKill: "Sitzung löschen",
  wrongAuth: "Falscher Benutzername oder Passwort",
  userDateExpired: "Benutzerlizenz ist abgelaufen",
  dateExpired: "Lizenz ist abgelaufen",
  userNoRole: "Benutzer hat keine Rolle",
  userNoAccess: "Benutzer hat keine Berechtigung für Designer",
  sessionExpired: "Sitzung abgelaufen",
  release: "Release",
  autologin: "Automatische Anmeldung",
  blue: "Blau",
  dark: "Darkmode",
  material: "Material",
  quartz: "Quartz",
  client: "Mandant"
};
